import Icon from "../../src/components/Icon";
import styles from "./notFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles["notFound"]}>
      <div className={styles["notFound__heading"]}>
        <h1 className={styles["notFound__heading-content"]}>404</h1>
        <div>
          <p>Ooops, there&apos;s nothing to see here</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
